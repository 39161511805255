import { generateGraphQLDocument, buildGraphQLVariables, authModeParams, getCustomHeaders, flattenItems, initializeModel } from '../APIClient.mjs';

function listFactory(client, modelIntrospection, model, context = false) {
    const listWithContext = async (contextSpec, args) => {
        return _list(client, modelIntrospection, model, args, contextSpec);
    };
    const list = async (args) => {
        return _list(client, modelIntrospection, model, args);
    };
    return context ? listWithContext : list;
}
async function _list(client, modelIntrospection, model, args, contextSpec) {
    const { name } = model;
    const query = generateGraphQLDocument(modelIntrospection, name, 'LIST', args);
    const variables = buildGraphQLVariables(model, 'LIST', args, modelIntrospection);
    try {
        const auth = authModeParams(client, args);
        const headers = getCustomHeaders(client, args?.headers);
        const { data, extensions } = contextSpec
            ? (await client.graphql(contextSpec, {
                ...auth,
                query,
                variables,
            }, headers))
            : (await client.graphql({
                ...auth,
                query,
                variables,
            }, headers));
        // flatten response
        if (data !== undefined) {
            const [key] = Object.keys(data);
            if (data[key].items) {
                const flattenedResult = flattenItems(data)[key];
                // don't init if custom selection set
                if (args?.selectionSet) {
                    return {
                        data: flattenedResult,
                        nextToken: data[key].nextToken,
                        extensions,
                    };
                }
                else {
                    const initialized = initializeModel(client, name, flattenedResult, modelIntrospection, auth.authMode, auth.authToken, !!contextSpec);
                    return {
                        data: initialized,
                        nextToken: data[key].nextToken,
                        extensions,
                    };
                }
            }
            return {
                data: data[key],
                nextToken: data[key].nextToken,
                extensions,
            };
        }
    }
    catch (error) {
        if (error.errors) {
            // graphql errors pass through
            return error;
        }
        else {
            // non-graphql errors re re-thrown
            throw error;
        }
    }
}

export { listFactory };

