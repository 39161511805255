/**
 * Given an introspection schema model, returns all owner fields.
 *
 * @param model Model from an introspection schema
 * @returns List of owner field names
 */
function resolveOwnerFields(model) {
    const ownerFields = new Set();
    for (const attr of model.attributes || []) {
        if (isAuthAttribute(attr)) {
            for (const rule of attr.properties.rules) {
                if (rule.allow === 'owner') {
                    ownerFields.add(rule.ownerField || 'owner');
                }
                else if (rule.allow === 'groups' && rule.groupsField !== undefined) {
                    // only valid for dynamic group(s)
                    // static group auth will have an array of predefined groups in the attribute, groups: string[]
                    // but `groupsField` will be undefined
                    ownerFields.add(rule.groupsField);
                }
            }
        }
    }
    return Array.from(ownerFields);
}
/**
 * Type guard that identifies an auth attribute with an attached rules list that
 * specifies an `allow` attribute at a minimum.
 *
 * @param attribute Any object. Ideally a model introspection schema model attribute
 * @returns True if given object is an auth attribute
 */
function isAuthAttribute(attribute) {
    if (attribute?.type === 'auth') {
        if (typeof attribute?.properties === 'object') {
            if (Array.isArray(attribute?.properties?.rules)) {
                return (attribute?.properties?.rules).every(rule => !!rule.allow);
            }
        }
    }
    return false;
}

export { resolveOwnerFields };

